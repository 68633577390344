<template>

  <div>

    <!-- Table Container Card -->
    <b-card
        no-body
        class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>显示</label>
            <v-select
                v-model="limit"
                :options="limitOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
            />
            <label>条</label>
          </b-col>

          <!-- Search -->
          <b-col
              cols="12"
              md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <!--<b-form-input
                v-model="searchKey"
                class="d-inline-block mr-1"
                placeholder="搜索关键字..."
              />
              <b-button
                variant="primary"
                :to="{ name: 'apps-product-edit'}"
                class="hidden"
              >
                <span class="text-nowrap">添加</span>
              </b-button>-->

              <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                  class="mr-1"
                  @click="advanced_search_excel"
                  v-if="readonly==0"
              >
                <feather-icon
                    icon="PlusIcon"
                    class="mr-50"
                />
                <span class="align-middle">批量导入</span>
              </b-button>


              <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                  class="mr-1"
                  @click="advanced_search"
              >
                <feather-icon
                    icon="SearchIcon"
                    class="mr-50"
                />
                <span class="align-middle">高级搜索</span>
              </b-button>

              <!--分类维护-->
<!--              <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-success"
                  class="mr-1"
                  @click="showCategoryFixModal"
              >
                <feather-icon
                    icon="ToolIcon"
                    class="mr-50"
                />
                <span class="align-middle">分类维护</span>
              </b-button>-->

              <!--清空勾选-->
<!--              <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-danger"
                  class="mr-1"
                  @click="clearSelected"
              >
                <feather-icon
                    icon="Trash2Icon"
                    class="mr-50"
                />
                <span class="align-middle">清空勾选</span>
              </b-button>-->
            </div>
          </b-col>
        </b-row>

        <b-form
            @submit.prevent="searchByCondition"
            @reset.prevent="resetCondition"
        >
          <b-card
              v-show="advanced_search_modal"
          >
            <b-row>
              <b-col
                  cols="12"
                  md="3"
              >
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="商品ID"
                    label-for="product_id"
                    label-size="sm"
                >
                  <b-form-input
                      v-model="product_id"
                      class="d-inline-block mr-1"
                      size="sm"
                  />
                </b-form-group>
              </b-col>
              <b-col
                  cols="12"
                  md="3"
              >
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="物料编码"
                    label-for="product_code"
                    label-size="sm"
                >
                  <b-form-input
                      v-model="product_code"
                      class="d-inline-block mr-1"
                      size="sm"
                  />
                </b-form-group>
              </b-col>

              <b-col
                  cols="12"
                  md="3"
              >
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="物料名称"
                    label-for="product_name"
                    label-size="sm"
                >
                  <b-form-input
                      v-model="product_name"
                      class="d-inline-block mr-1"
                      size="sm"
                  />
                </b-form-group>
              </b-col>

              <b-col
                  cols="12"
                  md="3"
              >
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="规格型号"
                    label-for="product_specification"
                    label-size="sm"
                >
                  <b-form-input
                      v-model="product_specification"
                      class="d-inline-block mr-1"
                      size="sm"
                  />
                </b-form-group>
              </b-col>

              <b-col
                  cols="12"
                  md="3"
              >
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="物料状态"
                    label-for="allot_type"
                    label-size="sm"
                >
                  <v-select
                      id="allot_type"
                      :options="getCodeOptions('product_status')"
                      :clearable="false"
                      v-model="product_status_label"
                      class="select-size-sm"
                      @input="changeProductStatus($event)"
                      placeholder="选择物料状态"
                  />
                </b-form-group>
              </b-col>

              <b-col
                  cols="12"
                  md="3"
              >
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="单份标准数量"
                    label-for="product_boxQuantity"
                    label-size="sm"
                >
                  <b-form-input
                      v-model="product_boxQuantity"
                      class="d-inline-block mr-1"
                      size="sm"
                      type="number"
                  />
                </b-form-group>
              </b-col>


              <b-col cols="12" md="3">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="分类"
                    label-for="category"
                    label-size="sm"
                >
                  <b-form-input
                      id="chooseId"
                      size="sm"
                      readonly
                      placeholder="请选择分类"
                      v-b-modal.modal-select-category
                      v-model="product_category_name"
                      class="d-inline-block mr-1"
                  />
                </b-form-group>
              </b-col>

              <b-modal
                  id="modal-select-category"
                  no-close-on-backdrop
                  ok-title="确认"
                  cancel-title="取消"
                  centered
                  @ok="onSelectCategory"
                  size="lg"
                  title="分类选择"
              >
                <category-select
                    :categoryOptions="category_options"
                    :level="level"
                    :showAll="true"
                    ref="categorySel">
                </category-select>

              </b-modal>

              <b-col cols="12" md="3">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="品牌"
                    label-for="brand_id"
                    label-size="sm"
                    class="mb-1"
                >
                  <v-select
                      id="brand_id"
                      :options="brandArray"
                      :clearable="false"
                      v-model="product_brandName"
                      @input="changeBrandId($event)"
                      placeholder="请选择品牌"
                      class="select-size-sm"
                  />
                </b-form-group>
              </b-col>

              <b-col cols="12" md="3">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="默认税率"
                    label-for="tax_rate"
                    label-size="sm"
                    class="mb-1"
                >
                  <v-select
                      id="tax_rate"
                      :options="getCodeOptions('tax_rate')"
                      :clearable="false"
                      v-model="product_taxRate_label"
                      class="select-size-sm"
                      @input="changeSelect('tax_rate', $event)"
                      placeholder="请选择默认税率"
                  />
                </b-form-group>
              </b-col>

              <!--              <b-col cols="12" md="3">
                              <b-form-group
                                  label-cols="3"
                                  label-cols-lg="3"
                                  label="保质期单位"
                                  label-for="guarantee_period_unit"
                                  label-size="sm"
                                  class="mb-1"
                              >
                                <v-select
                                    id="guarantee_period_unit"
                                    :options="getCodeOptions('product_unit_period')"
                                    :clearable="false"
                                    v-model="product_guaranteePeriodUnitLabel"
                                    @input="changeSelect('guarantee_period_unit',$event)"
                                    class="select-size-sm"
                                    placeholder="请选择保质期单位"
                                />
                              </b-form-group>
                            </b-col>-->
              <b-col cols="12" md="3">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="标签"
                    label-for="company_owner"
                    label-size="sm"
                    class="mb-1"
                >
                  <b-form-input
                      id="product_label"
                      size="sm"
                      v-model="product_label_name"
                      @click="showProductLabel()"
                      readonly
                  />
                </b-form-group>
              </b-col>


              <b-col cols="19" md="3">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="20"
                    label="保质期"
                    label-for="guarantee_period"
                    label-size="sm"
                    class="mb-1"
                    v-if="product_guaranteePeriodUnitLabel.length > 0 ? true: false"
                >
                  最小值:
                  <b-form-input
                      v-model="min_guarantee_period"
                      size="sm"
                      type="number"
                  />
                  最大值:
                  <b-form-input
                      v-model="max_guarantee_period"
                      size="sm"
                      type="number"
                  />
                </b-form-group>
              </b-col>
              <b-col
                  cols="12"
                  md="12"
                  class=" demo-inline-spacing d-flex align-items-center justify-content-end"
              >
                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    type="submit"
                >
                  <span class="align-right">查询</span>
                </b-button>
                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    type="reset"
                >
                  <span class="align-right">重置</span>
                </b-button>
                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    @click="advanced_search"
                >
                  <span class="align-right">关闭</span>
                </b-button>

                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-success"
                    @click="exportExcel"
                >
                  <span class="align-right">导出</span>
                </b-button>
              </b-col>
            </b-row>
          </b-card>
        </b-form>
        <b-form>
          <b-card
              v-show="advanced_search_modal_excel"
          >


            <b-row>
              <!--excel-->

              <b-col md="12">
                <b-form-group
                    label-cols="1"
                    label-cols-lg="1"
                    label="相关附件"
                    label-for="attachments_ids"
                    label-size="sm"
                    class="mb-1"
                >

                  <attachment-upload :theme="'files'"
                                     :object_type="'excel'"
                                     @change="onUploaded"
                  />
                </b-form-group>
              </b-col>

              <!--导入关闭-->
              <b-col
                  cols="12"
                  md="12"
                  class="demo-inline-spacing d-flex align-items-center justify-content-end"
              >
                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    @click="searchByConditionExcel"
                    v-if="clickExport"
                >
                  <span class="align-right">导入</span>
                </b-button>

                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    @click="advanced_search_excel"
                >
                  <span class="align-right">关闭</span>
                </b-button>
              </b-col>
            </b-row>

          </b-card>
        </b-form>
      </div>

      <b-table
          ref="refListTable"
          class="position-relative"
          :items="searchList"
          responsive
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="orderBy"
          show-empty
          empty-text="未找到记录"
          :sort-desc.sync="isSortDirDesc"
          v-model="table"
      >
        <!--复选框-->
<!--        <template v-slot:head(#)="data">
          <b-form-checkbox v-model="allchecked" @change="toggleAll" :indeterminate="indeterminate">
          </b-form-checkbox>
        </template>
        <template v-slot:cell(#)="row">
          <b-form-checkbox @change="checkboxClick(row.item)" v-model="isCheckedObj['index'+row.item.id]">
          </b-form-checkbox>
        </template>-->

        <!-- Columns -->
        <template #cell(product_id)="data">
          #{{ data.item.product_id }}
        </template>


        <!-- Column: Type -->
        <!--        <template #cell(company_owner)="data">-->
        <!--          {{getCodeLabel('company_owner', data.item.company_owner)}}-->
        <!--        </template>-->

        <!--        <template #cell(company_owner)="data">-->

        <!--          {{getCodeLabel('company_owner', data.item.company_owner)}}-->
        <!--        </template>-->

        <!--        status-->
        <template #cell(status)="data">
          <b-badge
              pill
              :variant="`light-${getCodeColor('product_status', data.item.status)}`"
          >
            {{ getCodeLabel('product_status', data.item.status) }}
          </b-badge>
        </template>


        <!--        product_attribute-->
        <template #cell(product_attribute)="data">
          {{ getCodeLabel('product_attribute', data.item.product_attribute) }}
        </template>

        <template #cell(guarantee_period_unit)="data">
          {{ getCodeLabel('product_unit_period', data.item.guarantee_period_unit) }}
        </template>


        <!--        product_unit-->
        <template #cell(product_unit)="data">
          {{ getCodeLabel('product_unit', data.item.product_unit) }}
        </template>


        <!-- Column: State -->
        <template #cell(state)="data">
          <b-link
              @click="changeState(data.item)"
          >
            <b-badge
                pill
                :variant="`light-${getCodeColor('state', data.item.state)}`"
            >
              {{ getCodeLabel('state', data.item.state) }}
            </b-badge>
          </b-link>
        </template>

        <template #cell(stockStr)="data">
          <b-link
              @click="showStock(data.item)"
              class="font-weight-bold d-block text-nowrap"
          >
            {{ data.item.stockStr }}
          </b-link>
        </template>

        <template #cell(product_label_name_ext)="data">
          <b-link
              @click="showProductLabelExt(data.item)"
              class="font-weight-bold d-block text-nowrap"
          >
            {{ data.item.ext.name.label_name }}
          </b-link>
        </template>

        <template #cell(tax_rate)="data">
          {{ data.item.tax_rate * 100 }}%
        </template>


        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
              variant="link"
              no-caret
          >

            <template #button-content>
              <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
              />
            </template>

            <b-dropdown-item :to="{ name: 'apps-product-edit', query: { id: data.item.id },params:{readonly:1} }">
              <feather-icon icon="EditIcon"/>
              <span class="align-middle ml-50">查看产品</span>
            </b-dropdown-item>

            <b-dropdown-item v-if="readonly==0&&data.item.status!=3"
                             :to="{ name: 'apps-product-edit', query: { id: data.item.id },params:{readonly:0} }">
              <feather-icon icon="EditIcon"/>
              <span class="align-middle ml-50">编辑产品</span>
            </b-dropdown-item>

            <b-dropdown-item v-if="data.item.status==2||data.item.status==5" @click="changeStatus(data.item,3)">
              <feather-icon icon="EditIcon"/>
              <span class="align-middle ml-50">提交审核</span>
            </b-dropdown-item>

            <b-dropdown-item v-if="readonly==0&&data.item.status!=3" @click="changeBox(data.item)">
              <feather-icon icon="EditIcon"/>
              <span class="align-middle ml-50">修改箱规</span>
            </b-dropdown-item>

            <b-dropdown-item :to="{ name: 'apps-stocklock-list',query:{productid: data.item.id} }">
              <feather-icon icon="EditIcon"/>
              <span class="align-middle ml-50">所有锁库</span>
            </b-dropdown-item>

            <b-dropdown-item @click="mystocklock(data.item.id)">
              <feather-icon icon="EditIcon"/>
              <span class="align-middle ml-50">我的锁库</span>
            </b-dropdown-item>

            <b-dropdown-item v-b-modal.modal-stock-log @click="changeId(data.item)">
              <feather-icon icon="EditIcon"/>
              <span class="align-middle ml-50">库存日志</span>
            </b-dropdown-item>

            <b-dropdown-item v-b-modal.modal-product-log @click="changeModifyLog(data.item)">
              <feather-icon icon="EditIcon"/>
              <span class="align-middle ml-50">修改日志</span>
            </b-dropdown-item>

            <b-dropdown-item v-if="readonly==0" @click="changeState(data.item)">
              <feather-icon icon="EditIcon"/>
              <span class="align-middle ml-50">设置为无效</span>
            </b-dropdown-item>

            <!--                        <b-dropdown-item @click="copyRow(data.item)">-->
            <!--                          <feather-icon icon="EditIcon" />-->
            <!--                          <span class="align-middle ml-50">复制行</span>-->
            <!--                        </b-dropdown-item>-->
          </b-dropdown>
        </template>

      </b-table>

      <b-modal
          id="modal-stock-log"
          ok-only
          centered
          size="xl"
          title="库存日志"
      >
        <stock-log-list :productid="id" :stockid="''"></stock-log-list>
      </b-modal>

      <b-modal
          id="modal-product-log"
          hide-footer
          centered
          size="xl"
          title="修改日志"
      >
        <modify-log-list :objectTypeProduct="1" :productid="id"></modify-log-list>
      </b-modal>

      <div class="mx-2 mb-2">
        <b-row>

          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">显示 {{ dataMeta.from }} - {{ dataMeta.to }} / {{ dataMeta.of }} 记录</span>
          </b-col>
          <!-- Pagination -->
          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
                v-model="start"
                :total-rows="listTotals"
                :per-page="limit"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>

      <b-modal
          id="modal-single"
          ok-only
          ok-title="确认"
          @ok="onSelectWHFromPeople"
          cancel-title="取消"
          centered
          size="lg"
          :title="'请选择'"
          ref="productLabel"
      >
        <ProductLabelList
            ref="whSelect" v-on:table="fromChildren($event,'from')"
        >
        </ProductLabelList>
      </b-modal>

      <b-modal
          id="modal-single"
          ok-only
          ok-title="确认"
          @ok="selectProductLabel"
          cancel-title="取消"
          centered
          size="lg"
          :title="'请选择标签'"
          ref="productLabelExt"
      >
        <ProductLabelList
            ref="labelSelect" v-on:table="fromChildren($event,'from')"
        >
        </ProductLabelList>
      </b-modal>

      <b-modal
          id="stockModal"
          ref="stockModal"
          ok-only
          ok-title="确认"
          @hide="hideModal"
          cancel-title="取消"
          centered
          size="xl"
          title="库存列表"
      >
        <stock-show-modal
            ref="stockShowModal" :productId="productId">
        </stock-show-modal>
      </b-modal>


      <!-- 复制行 模态框 -->
      <b-modal
          id="copyRowDataModal"
          ref="copyRowDataModal"
          centered
          size="lg"
          title="复制行"
          hide-footer
          @hide="hideCopyRowModal"
      >
        <b-form-group
            label-cols="5"
            label-cols-lg="3"
            label="商品编码(69码)"
            label-for="code"
            label-size="sm"
            class="mb-1 required"
        >
          <!--  v-model="product.code" -->
          <div>
            <b-form-input
                id="code"
                size="sm"
                key="productCode"
                @keyup.native="btKeyUp"
                v-model="productCode"
                onkeyup="value=value.replace(/[\W]/g,'') "
                onbeforepaste="clipboardData.setData('text',clipboardData.getData('text').replace(/[^\d]/g,''))"
            />
          </div>
        </b-form-group>
        <b-form-group
            label-cols="5"
            label-cols-lg="3"
            label="商品名称"
            label-for="productName"
            label-size="sm"
            class="mb-1 required"
        >
          <div>
            <b-form-input
                id="productName"
                size="sm"
                v-model="productName"
            />
          </div>
        </b-form-group>
        <b-form-group
            label-cols="5"
            label-cols-lg="3"
            label="商品规格"
            label-for="specification"
            label-size="sm"
            class="mb-1 required"
        >
          <div>
            <b-form-input
                id="specification"
                size="sm"
                v-model="specification"
            />
          </div>
        </b-form-group>
        <b-form-group
            label-cols="5"
            label-cols-lg="3"
            label="基本单位"
            label-for="product_unit"
            label-size="sm"
            class="mb-1 required"
        >
          <div>
            <v-select
                id="product_unit"
                :options="getCodeOptions('product_unit')"
                :clearable="true"
                v-model="productUnit"
                :reduce="option => option.value"
                class="select-size-sm"
            />
          </div>
        </b-form-group>
        <b-form-group
            label-cols="5"
            label-cols-lg="3"
            label="单箱标准数量"
            label-for="box_quantity"
            label-size="sm"
            class="mb-1 required"
        >
          <div>
            <b-form-input
                id="boxQuantity"
                size="sm"
                type="number"
                v-model="boxQuantity"
                @mousewheel.prevent
            />
          </div>
        </b-form-group>
        <b-button
            size="sm"
            variant="outline-primary" @click="submitCopyRowData" style="float: right">确定
        </b-button>
      </b-modal>

      <!--      修改箱规-->
      <b-modal
          id="productBoxModal"
          ref="productBoxModal"
          ok-only
          ok-title="关闭"
          centered
          :size="showBoxModal==='product'?'lg':'xl'"
          title="修改箱规"
      >
        <div v-if="showBoxModal==='product'">
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="物料名称:"
              label-for="name"
              label-size="sm"
              class="mb-1"
          >
            <b-form-input
                size="sm"
                v-model="boxProduct.name"
                readonly
            />
          </b-form-group>
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="修改前商品箱规:"
              label-for="product_id"
              label-size="sm"
              class="mb-1"
          >
            <b-form-input
                size="sm"
                v-model="boxProduct.box_quantity"
                readonly
            />
          </b-form-group>

          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="修改后商品箱规:"
              label-for="product_id"
              label-size="sm"
              class="mb-1 required"
          >
            <b-form-input
                size="sm"
                v-model="newBoxQuantity"
                type="number"
            />
          </b-form-group>

          <b-row>
            <b-col cols="10"></b-col>
            <b-col
                cols="2"
            >
              <b-button
                  variant="primary"
                  class="mr-1"
                  @click="saveProductBox"
              >
                保存
              </b-button>
            </b-col>
          </b-row>

        </div>

        <product-box-edit v-if="showBoxModal==='productBox'" :product="boxProduct" v-on:change="closeModal">

        </product-box-edit>


      </b-modal>

      <!--分类维护选择模态框-->
      <b-modal
          id="category-fix-modal"
          ref="category-fix-modal"
          no-close-on-backdrop
          ok-title="确认"
          cancel-title="取消"
          centered
          @ok="categoryFix"
          size="lg"
          title="分类选择"
      >
        <category-select
            :categoryOptions="category_options"
            :level="level"
            :showAll="true"
            ref="categoryFixRef">
        </category-select>

      </b-modal>

    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import {ref, onUnmounted} from '@vue/composition-api'
import {avatarText, getCode, getCodeColor, getCodeLabel, getCodeOptions, isEmpty} from '@core/utils/filter'
import productUseList from './productUseList'
import productStore from './productStore'
import StockShowModal from '@/views/apps/stock/stock-show-modal/StockShowModal'
import {getUserData} from '@/auth/utils'
import StockLogList from "@/views/apps/stocklog/StockLogList";

import Image from "@/views/components/image/Image";
import {useToast} from "vue-toastification/composition";
import Ripple from 'vue-ripple-directive'
import CategorySelect from '@/views/apps/category/category-modal/CategorySelect'
import brandStore from "@/views/apps/brand/brandStore";
import instance from "@/libs/axios";
import AttachmentUpload from '@/views/apps/attachment/AttachmentUpload'
import ProductLabelList from "@/views/apps/productlabel/productlabel_modal/ProductLabelList";
import ProductBoxEdit from "@/views/apps/productbox/ProductBoxEdit";
import ModifyLogList from "@/views/apps/modifylog/ModifyLogList";
import modifylogStore from "@/views/apps/modifylog/modifylogStore";
import axios from "@/libs/axios";


export default {
  components: {
    ProductBoxEdit,
    //Image,
    ProductLabelList,
    AttachmentUpload,
    StockLogList,
    StockShowModal,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    CategorySelect,
    ModifyLogList,
  },
  directives: {
    Ripple,
  },
  watch: {
    checked: {
      handler(newVal, oldVal) {
        let show = 0;
        const tableIdArray = []
        this.table.forEach((value) => {
          tableIdArray.push(value.id)
        })
        this.checked.forEach((value) => {
          if (tableIdArray.indexOf(Number(value.id)) > -1) {
            show += 1;
          }
        })
        if (this.table.length === show && show > 0) {
          this.indeterminate = false
          this.allchecked = true
        } else if (show === 0) {
          this.indeterminate = false
          this.allchecked = false
        } else {
          this.indeterminate = true
          this.allchecked = false
        }
        this.$forceUpdate()
      },
      deep: true//深度
    },
    table: {
      handler(newVal, oldVal) {
        let show = 0;
        const tableIdArray = []
        this.table.forEach((value) => {
          tableIdArray.push(value.id)
        })
        this.checked.forEach((value) => {
          if (tableIdArray.indexOf(Number(value.id)) > -1) {
            show += 1;
          }
        })
        if (this.table.length === show && show > 0) {
          this.indeterminate = false
          this.allchecked = true
        } else if (show === 0) {
          this.indeterminate = false
          this.allchecked = false
        } else {
          this.indeterminate = true
          this.allchecked = false
        }
        this.$forceUpdate()
      },
      deep: true//深度
    },
  },
  data() {
    return {
      selected: [],
      productId: 0,
      stockStr: "查看",
      selectedItem: {},
      userid: 0,
      id: 0,
      url: process.env.VUE_APP_OSS_URL,
      productCode: "",
      productName: "",
      specification: "",
      productUnit: "",
      boxQuantity: "",
      judgeDisplay: undefined,
      rowData: [],
      advanced_search_modal: false,
      product_category_name: "",
      level: 3,
      category_options: [],
      brandArray: [],
      product_brandName: '',
      product_taxRate_label: '',
      product_guaranteePeriodUnitLabel: '',
      product_status_label: '',
      advanced_search_modal_excel: false,
      excelSqlId: '',
      companyNameExcel: '',
      product_label_name: '',
      productEdit: '',
      readonly: 1,
      resultProduct: true,
      product_label_id_ext: '',

      boxProduct: {},
      newBoxQuantity: '',
      showBoxModal: '',
      clickExport: true,
      allchecked: false,
      indeterminate: false,
      isCheckedObj: {},
      table: [],
      checked: [],
      checkedIds: [],
    }
  },
  methods: {
    onSelectWHFromPeople() {
      let returnData = {}
      if (this.$refs.whSelect.getSelected().length > 0) {
        returnData = this.$refs.whSelect.getSelected()[0]
        this.product_label_id = returnData.label_id
        this.product_label_name = returnData.label_name
        this.$forceUpdate()
      }
    },
    btKeyUp(e) {
      e.target.value = e.target.value.replace(/[`~!@#$%^&*()_\-+=<>?:"{}|,.\/;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘’，。、]/g, "");
    },
    changeState: function (data) {
      if (confirm("确定设置无效吗?")) {
        const state = 1 - data.state
        store.dispatch('product/state', {id: data.id, state: state}).then(res => {
          data.state = state
          const memo = "商品设置无效"
          store.dispatch('modifylog/save', {
            object_type: 1,
            object_id: data.product_id,
            modify_content: memo
          }).then(res => {
          })
          this.refetchData()
        })
      }
    },
    changeModifyLog(data) {
      this.id = data.product_id
    },


    showStock(item) {
      this.selectedItem = item
      this.productId = item.id
      this.$refs['stockModal'].show()
    },
    hideModal() {
      this.stockStr = this.$refs.stockShowModal.getStockStr();
      this.selectedItem['stockStr'] = this.stockStr;
    },
  },
  setup() {
    const toast = useToast()
    // Register module
    if (!store.hasModule('product')) store.registerModule('product', productStore)

    if (!store.hasModule('brand')) store.registerModule('brand', brandStore)

    if (!store.hasModule('modifylog')) store.registerModule('modifylog', modifylogStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('product')) store.unregisterModule('product')

      if (store.hasModule('brand')) store.unregisterModule('brand')

      if (store.hasModule('modifylog')) store.unregisterModule('modifylog')
    })

    const mystocklock = function (id) {
      this.$router.push({path: '/apps/stocklock/list?productid=' + id + '&creator=' + this.userid})
    }

    const changeId = function (val) {
      this.id = val.product_id
    }

    const advanced_search_excel = function () {
      this.advanced_search_modal_excel = this.advanced_search_modal_excel ? false : true;
    }

    const changeStatus = function (params, status) {
      if (confirm("确定提交审核吗?")) {
        store.dispatch('product/changeStatus', {productid: params.product_id, status: status}).then(res => {
          if (res.data.code === 0) {
            toast.success("审核成功!")
            const memo = "提交审核商品"
            store.dispatch('modifylog/save', {
              object_type: 1,
              object_id: params.product_id,
              modify_content: memo
            }).then(res => {
            })
            refetchData()
          } else {
            toast.error(res.data.data)
            refetchData()
          }
        })
      }
    }

    const copyRow = function (params) {
      this.$refs['copyRowDataModal'].show()
      this.rowData = params
    }

    const submitCopyRowData = async function (param) {
      if (this.productCode === undefined || this.productCode === "") {
        toast.error('商品编码(69码)必填!')
        return false
      }

      if (this.productName === undefined || this.productName === "") {
        toast.error('商品名称必填!')
        return false
      }

      if (this.productUnit === undefined || this.productUnit === "" || this.productUnit == null) {
        toast.error('基本单位必填!')
        return false
      }

      if (this.specification === undefined || this.specification === "") {
        toast.error('商品规格必填!')
        return false
      }

      if (this.boxQuantity === undefined || this.boxQuantity === "") {
        toast.error('单箱标准数量必填!')
        return false
      }


      this.rowData.code = ',' + this.productCode + ','
      this.rowData.name = this.productName
      this.rowData.specification = this.specification
      this.rowData.box_quantity = this.boxQuantity
      this.rowData.product_unit = this.productUnit

      this.rowData.label = this.productName.replace(this.specification, "").replace(" ", "")

      await store.dispatch('product/copyProduct', this.rowData).then(res => {
        if (res.data.code === 0) {
          toast.success('添加成功！')
          this.$refs['copyRowDataModal'].hide()
        } else {
          toast.error('添加失败！' + res.data.data)
        }
      })
      this.$refs['refListTable'].refresh()
    }

    const hideCopyRowModal = function () {
      this.productCode = ""
      this.productName = ""
      this.specification = ""
      this.productUnit = ""
      this.boxQuantity = ""
    }

    const advanced_search = function () {
      this.advanced_search_modal = this.advanced_search_modal ? false : true;
    }

    //yang
    const searchByConditionExcel = function () {
      this.clickExport = false;
      const params = {
        excelSqlId: this.excelSqlId,
      }
      store.dispatch('product/export', params)
          .then(response => {
            const data = response.data.data
            if (response.data.code == 0) {
              toast.success("导入成功")
              refetchData();
            } else {
              toast.error(data)
            }
          })

    }

    const onUploaded = function (id, attachment, result) {
      this.excelSqlId = result
    }
    const onSelectCategory = function () { //赋值回显
      this.product_category_id = this.$refs.categorySel.categoryId + "," + this.$refs.categorySel.categoryTowId + "," + this.$refs.categorySel.categoryThreeId;
      this.category_options = [];
      this.category_options.push(
          {
            "label": this.$refs.categorySel.categoryName,
            "category_id": this.$refs.categorySel.categoryId,
          },
          {
            "label": this.$refs.categorySel.categoryTowName,
            "category_id": this.$refs.categorySel.categoryTowId,
          },
          {
            "label": this.$refs.categorySel.categoryThreeName,
            "category_id": this.$refs.categorySel.categoryThreeId,
          },
      );
      var new_content = this.$refs.categorySel.categoryName + "/";
      new_content += this.$refs.categorySel.categoryTowName + "/";
      new_content += this.$refs.categorySel.categoryThreeName + "/";
      this.product_category_name = new_content;
    }

    const changeBrandId = function (event) {
      if (event != null) {
        this.product_brandId = event.brand_id
      }
    }

    const getBrand = function (d) {
      store.dispatch('brand/search').then(res => {
        let a = res.data.data.list
        for (let i = 0; i < a.length; i++) {
          d.push({"label": a[i].name, "brand_id": a[i].brand_id})
        }
      })
    }

    const changeSelect = function (key, event) {
      if (event != null) {
        if (key === 'tax_rate') {
          this.product_taxRate_label = getCode("tax_rate", event.value).label
          this.product_taxRate = getCode("tax_rate", event.value).value
        } else if (key === 'guarantee_period_unit') {
          this.product_guaranteePeriodUnitLabel = getCode('product_unit_period', event.value).label
          this.product_guaranteePeriodUnit = getCode('product_unit_period', event.value).value
        }
      } else {
        if (key === 'tax_rate') {
          this.product_taxRate = ''
          this.product_taxRate_label = ''
        } else if (key === 'guarantee_period_unit') {
          this.product_guaranteePeriodUnitLabel = ''
          this.product_guaranteePeriodUnit = ''
        }
      }
    }

    const changeProductStatus = function (event) {
      if (event != null) {
        this.product_status_label = event.label
        this.product_status = event.value
      }
    }

    const searchByCondition = function () {
      let min = parseInt(this.min_guarantee_period)
      let max = parseInt(this.max_guarantee_period)
      if (max < min || min < 0) {
        this.$toast.error("保质期范围最小值/最大值错误")
      } else {
        this.refetchData()
      }
    }

    const showProductLabel = function () {
      this.$refs['productLabel'].show()
    }

    const fromChildren = function (data, modal) {

      if (modal == 'from') {
        this.product_label_id = data.label_id
        this.product_label_name = data.label_name
        this.$forceUpdate()
      }
      this.$bvModal.hide('modal-single')
    }

    const selectProductLabel = function () {
      let returnData = {}
      let returnDataLabelId;
      let returnDataLabelName;
      if (this.$refs.labelSelect.getSelected().length > 0) {
        returnData = this.$refs.labelSelect.getSelected()[0]
        if (this.product_label_id == returnData.label_id) {
          this.resultProduct = true
        } else {
          this.resultProduct = false
        }

        if (returnData.label_name == '<空>') {
          returnDataLabelId = 0
          returnDataLabelName = returnData.label_name
        } else {
          returnDataLabelId = returnData.label_id
          returnDataLabelName = returnData.label_name
        }
        store.dispatch('product/save', {
          ...this.product_label_id_ext,
          label_id: returnDataLabelId,
          resultProduct: this.resultProduct,
          label_ym: true,
        }).then(res => {
          if (res.data.code === 0) {
            toast.success('数据已保存!')
            const memo = "修改标签 " + this.product_label_id_ext.ext.name.label_name + "->" + returnDataLabelName
            store.dispatch('modifylog/save', {
              object_type: 1,
              object_id: this.product_label_id_ext.product_id,
              modify_content: memo
            }).then(res => {
            })
            this.refetchData()
          } else {
            toast.error(res.data.data)
          }
        })
      }
    }
    const showProductLabelExt = function (item) {
      this.product_label_id_ext = item
      if (this.readonly == 0) {
        this.$refs['productLabelExt'].show()
      } else {
        toast.error("对不起，您没有修改标签的权限")
      }
    }


    const resetCondition = function () {
      this.product_id = ''
      this.product_code = ''
      this.product_name = ''
      this.product_specification = ''
      this.product_status_label = ''
      this.product_status = ''
      this.product_boxQuantity = ''
      this.product_category_name = ''
      this.product_category_id = ''
      this.product_brandName = ''
      this.product_brandId = ''
      this.product_taxRate = ''
      this.product_taxRate_label = ''
      this.product_guaranteePeriodUnit = ''
      this.product_guaranteePeriodUnitLabel = ''
      this.min_guarantee_period = ''
      this.max_guarantee_period = ''
      this.product_label_name = ''
      this.product_label_id = ''

      this.refetchData()
    }

    const changeBox = function (params) {
      this.boxProduct = {}
      this.newBoxQuantity = ''
      store.dispatch('product/changeBox', params).then(res => {
        if (res.data.code == 0) {
          toast.success(res.data.data)
          this.boxProduct = params
          if (res.data.data === '当前商品没有库存,可直接修改！') {
            this.showBoxModal = 'product'
          }
          if (res.data.data === '当前商品已有库存,请新建箱规修改申请！') {
            this.showBoxModal = 'productBox'
          }
          this.$refs['productBoxModal'].show()
        } else {
          toast.error(res.data.data)
        }
      })
      this.$forceUpdate()

    }

    const saveProductBox = function () {
      if (this.newBoxQuantity === '' || this.newBoxQuantity === undefined) {
        toast.error("单箱标准数量必填!")
        return false
      }
      if (this.boxProduct.box_quantity == this.newBoxQuantity) {
        toast.error("修改后的单箱标准数量不能和修改前的相同!")
        return false
      }
      const cRegExp = /^[1-9]\d*$/
      if (!cRegExp.test(this.newBoxQuantity)) {
        toast.error('单箱标准数量必须是正整数!')
        return false
      }
      store.dispatch('product/saveProductBox', {
        id: this.boxProduct.product_id,
        newBoxQuantity: this.newBoxQuantity
      }).then(res => {
        if (res.data.code == 0) {
          toast.success("修改成功")
          refetchData()
        } else {
          toast.error(res.data.data)
          refetchData()
        }
      })
      this.$refs['productBoxModal'].hide()
      this.$forceUpdate()
    }

    const closeModal = function (val) {
      if (val == "success") this.$refs['productBoxModal'].hide()
    }

    // 点击全选按钮
    const toggleAll = function (checked) {
      let checkedIds = [];
      this.checked.forEach(item => {
        checkedIds.push(Number(item.id))
      })
      this.table.forEach(element => {
        if (checked) {
          if (checkedIds.indexOf(element.id) === -1) {
            this.checked.push(element);
          }
        } else {
          //取消勾选
          for (let i = 0; i < this.checked.length; i++) {
            //在已勾选数组中找出要取消勾选的
            if (this.checked[i].id == element.id) {
              this.checked.splice(i, 1)
            }
          }
        }
        this.isCheckedObj['index' + element.id] = checked;
      });
      this.$forceUpdate()
    }
    // 复选框点击选中与取消
    const checkboxClick = function (item) {
      if (this.isCheckedObj['index' + item.id]) {
        //勾选
        this.checked.push(item)
      } else {
        //取消勾选
        for (let i = 0; i < this.checked.length; i++) {
          //在已勾选数组中找出要取消勾选的
          if (this.checked[i].id == item.id) {
            this.checked.splice(i, 1)
          }
        }
      }
      this.$forceUpdate()
    }
    //清空勾选
    const clearSelected = function () {
      this.checked.forEach(item => {
        this.isCheckedObj['index' + item.id] = false;
      })
      this.checked = []
    }
    //分类维护
    const showCategoryFixModal = function () {
      if (this.checked.length < 1) {
        toast.error('请选择要维护的商品')
        return false
      }
      this.$refs['category-fix-modal'].show()
    }
    //提交分类维护
    const categoryFix = function (bvModalEvt) {
      let selectOne = this.$refs.categoryFixRef.categoryId
      let selectTwo = this.$refs.categoryFixRef.categoryTowId
      let selectThree = this.$refs.categoryFixRef.categoryThreeId
      if(isEmpty(selectOne)){
        bvModalEvt.preventDefault()
        toast.error('请选择 一级分类')
        return
      }
      if(isEmpty(selectTwo)){
        bvModalEvt.preventDefault()
        toast.error('请选择 二级分类')
        return
      }
      if(isEmpty(selectThree)){
        bvModalEvt.preventDefault()
        toast.error('请选择 三级分类')
        return
      }
      let ids = []
      for (let i = 0; i < this.checked.length; i++) {
        ids.push(this.checked[i].product_id)
      }
      let param = {ids: ids.toString(), one: selectOne, two: selectTwo, three: selectThree}
      store.dispatch('product/categoryFix', param).then(res => {
        if (res.data.code == 0) {
          this.$swal({
            icon: 'success',
            title: '操作成功!',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
          //清空勾选
          this.clearSelected()
          refetchData()
        } else {
          toast.error(res.data.data)
        }
      })
    }

    const exportExcel = function () {
      axios({
            url: '/api/exportexcelfile/productExport',
            method: 'get',
            params: {
              product_id: product_id.value,
              product_code: product_code.value,
              product_name: product_name.value,
              product_specification: product_specification.value,
              product_status: product_status.value,
              product_boxQuantity: product_boxQuantity.value,
              product_category_id: product_category_id.value,
              product_brandId: product_brandId.value,
              product_taxRate: product_taxRate.value,
              product_guaranteePeriodUnit: product_guaranteePeriodUnit.value,
              min_guarantee_period: min_guarantee_period.value,
              max_guarantee_period: max_guarantee_period.value,
              product_label_id: product_label_id.value
            },
            responseType: 'blob',
          }
      ).then(res => {
        toast.success("导出成功")
        let data = new Blob([res.data], {type: 'application/vnd.ms-excel'})   //接收的是blob，若接收的是文件流，需要转化一下
        let filename = "商品-" + new Date().getTime();
        /*-------------*/
        const link = document.createElement('a')
        link.style.display = 'none'
        link.href = URL.createObjectURL(data);
        link.setAttribute('download', filename + '.xlsx')
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      })
    }

    const {
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,

      // 查询条件
      product_code,
      product_id,
      product_name,
      product_specification,
      product_status,
      product_boxQuantity,
      product_category_id,
      product_brandId,
      product_taxRate,
      product_guaranteePeriodUnit,
      min_guarantee_period,
      max_guarantee_period,
      product_label_id,
    } = productUseList()

    return {
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,

      // Filter
      avatarText,

      // UI
      getCodeLabel,
      getCode,
      getCodeColor,
      getCodeOptions,
      mystocklock,
      changeId,
      changeStatus,
      copyRow,
      submitCopyRowData,
      hideCopyRowModal,
      advanced_search,
      onSelectCategory,
      changeBrandId,
      getBrand,
      changeSelect,
      searchByCondition,
      resetCondition,
      changeProductStatus,
      advanced_search_excel,
      searchByConditionExcel,
      onUploaded,
      showProductLabel,
      fromChildren,
      showProductLabelExt,
      changeBox,
      saveProductBox,
      closeModal,
      toggleAll,
      checkboxClick,
      clearSelected,
      categoryFix,
      showCategoryFixModal,
      exportExcel,

      // 查询条件
      product_code,
      product_id,
      product_name,
      product_specification,
      product_status,
      product_boxQuantity,
      product_category_id,
      product_brandId,
      product_taxRate,
      product_guaranteePeriodUnit,
      min_guarantee_period,
      max_guarantee_period,
      product_label_id,
      selectProductLabel,
    }
  },

  created() {
    const userData = getUserData()
    this.userid = userData.user_id

    if (userData.role_id == 14) {
      this.readonly = 0
    }

    this.getBrand(this.brandArray)
    this.productEdit = getCodeLabel("setting", "product_edit")
    let array = this.productEdit.split(",")
    for (let i = 0; i < array.length; i++) {
      if (userData.user_id == array[i]) {
        this.readonly = 0
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
