<template>
  <b-card
    class="productbox-edit-wrapper"
  >
    <!-- form -->
    <b-form id="productboxForm" class="edit-form mt-2">
      <b-row>
        <b-col md="12" v-if="product!=null">
          <b-alert
              variant="primary"
              show
          >
            <div class="alert-body">
              <span><strong>{{product==null?null:product.name}}</strong></span>
            </div>
          </b-alert>
        </b-col>
        <b-col md="6">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="修改前单箱标准数量"
            label-for="box_quantity_source"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="box_quantity_source"
              size="sm"
              v-model="product==null?productbox.box_quantity_source:product.box_quantity"
              readonly
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="修改后单箱标准数量"
            label-for="box_quantity_after"
            label-size="sm"
            class="mb-1 required"
          >
            <b-form-input
              id="box_quantity_after"
              size="sm"
              v-model="productbox.box_quantity_after"
              type="number"
            />
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-form-group
            label-cols="3"
            label-cols-lg="1"
            label="修改原因"
            label-for="reason"
            label-size="sm"
            class="mb-1"
          >
            <b-form-textarea
                id="reason"
                size="sm"
                v-model="productbox.reason"
                rows="3"
                max-rows="6"
            />
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-form-group
            label-cols="1"
            label-cols-lg="1"
            label="附件"
            label-for="attachments"
            label-size="sm"
            class="mb-1"
          >
            <attachment-upload v-if="productbox.loaded" :theme="'files'"
                               :attachment_id="'attachments'"
                               :id="productbox.attachments"
                               :object_type="'product_box'"
                               :object_id="productbox.box_id"
                               @change="onUploaded"
            />
          </b-form-group>
        </b-col>
                <b-col
          cols="12"
          class="mt-50"
        >
          <b-button
            variant="primary"
            class="mr-1"
            @click="save"
          >
            保存
          </b-button>
          <b-button
            variant="outline-secondary"
            @click="cancel"
          >
            取消
          </b-button>
        </b-col>
      </b-row>
    </b-form>
    <!--/ form -->
  </b-card>
</template>

<script>
import {
  BCard, BMedia, BAvatar, BCardText, BForm, BRow, BCol, BFormGroup, BFormInput, BImg, BFormFile, BLink, BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {onUnmounted, ref} from '@vue/composition-api'
import router from "@/router";
import store from "@/store";
import productboxStore from './productboxStore'
// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { toTime, toDate, getCodeOptions, getCode, getCodeLabel, getCodeColor } from '@core/utils/filter'
import AttachmentUpload from '@/views/apps/attachment/AttachmentUpload'

export default {
  components: {
    BCard,
    BMedia,
    BAvatar,
    BCardText,
    BForm,
    BLink,
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    vSelect,
    useToast,
    AttachmentUpload,
  },
  data() {
    return {
      id: ref(0),
      productbox: ref({}),
    }
  },
  props:{
    product:{

    }
  },
  setup() {
    const toast = useToast()

    // Register module
    if (!store.hasModule('productbox')) store.registerModule('productbox', productboxStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('productbox')) store.unregisterModule('productbox')
    })

    const edit = function() {
      store.dispatch('productbox/edit', {id: this.id}).then(res => {
        this.productbox = res.data.data
      })
    }

    const view = function() {
      store.dispatch('productbox/view', {id: this.id}).then(res => {
        this.productbox = res.data.data
      })
    }

    const cancel = function() {
      this.$router.go(-1)
    }

    const save = function() {
      this.productbox.product_id = (this.product==null?this.productbox.product_id:this.product.product_id)
      this.productbox.box_quantity_source = (this.product==null?this.productbox.box_quantity_source:this.product.box_quantity)
      if (this.productbox.box_quantity_after === '' || this.productbox.box_quantity_after === undefined) {
        toast.error("单箱标准数量必填!")
        return false
      }
      if (this.productbox.box_quantity_after==this.productbox.box_quantity_source){
        toast.error("修改后的单箱标准数量不能和修改前的相同!")
        return false
      }
      const cRegExp = /^[1-9]\d*$/
      if (!cRegExp.test(this.productbox.box_quantity_after)) {
        toast.error('单箱标准数量必须是正整数!')
        return false
      }
      store.dispatch('productbox/save', this.productbox).then(res => {
        if (res.data.code==0){
          toast.success('数据已保存!')
          this.$emit("change","success")
        }else {
          toast.error(res.data.data)
        }

      })
    }

    const onUploaded = function (id, attachment, result) {
      this.productbox[id] = result
    }

    return {
      edit,
      view,
      cancel,
      save,
      
      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,

      onUploaded,
    }
  },
  created() {
    this.id = this.$route.query.id || 0;
    this.edit()
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>